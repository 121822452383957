import React, {useEffect} from 'react';
import "./about.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from '../components/Footer/Footer';

import {
    Link
  } from "react-router-dom";

function AboutPage() {
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
  return (
    <>
     <div className="containerdiv">
        <section className="pageBannerA">
          <div className="imageLayer img"></div>
          <div data-aos="fade-up" className="autoContainer">
            <h1>A propos de Tratop Ingénierie</h1>
            <ul className="subText">
              <li>
                <a href="/">Accueil </a>
              </li>
              <li> / </li>
              <li className="act">
                <a href="/about"> A propos</a>
              </li>
            </ul>
          </div>
        </section>
        <section style={{ paddingTop: "40px", backgroundColor: "#F5F3F4" }}>
          <div className="apropos">
            <div
              data-aos="fade-right"
              className="flex_row img_container"
            >
              <img src="./assets/allimages/Survey.jpg" />
            </div>
            <div className="flex_row">
              <h1>Notre histoire</h1>
              <p className="text">
                Notre Société Tratop Ingénierie, créée en 2001 , continue son
                développement et son implication dans le but de répondre aux
                besoins du marché Marocain en matières d’expertise immobilière,
                d'études spécifiques, de prestations topographiques, de dossiers
                cadastraux, et de solutions géomatiques.
              </p>
            </div>
          </div>
          <div className = "apropos">
            <div className= "flex_row">
              <h1>Nos Travaux</h1>
              <p className="textTwo">
              ✔️ Réalisation des études topographiques <br />
              ✔️ L'acquisition et le traitement et de données relevant de la
                numérisation terrestre les prises de vues aériennes et la
                photogrammétrie, la cartographie, les SIG et l'expertise
                immobilière. <br /> ✔️ Modèles BIM. <br /> ✔️ Numérisation 3D /
                Capture de panorama, navigation intérieure révolutionnaire basée
                sur la vision, livrer des nuages de points sur une page web.{" "}
                <br /> ✔️ Agriculture de précision. 
              </p>
            </div>
            <div
              data-aos="fade-left"
              className="flex_row img_container"
            >
              <img src="./assets/allimages/work.jpg" />
            </div>
          </div>
        </section>
        </div>
        <Footer />
        </>
  )
}

export default AboutPage;