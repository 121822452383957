import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./stationtotal.css";
import Footer from '../components/Footer/Footer';


function StationTotal() {
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
  return (
<>
      <div>
        <section className="pageBannerT">
          <div className="imageLayer img"></div>
          <div  data-aos="fade-up" className="autoContainer">
            <h1>Station Totale et GPS</h1>
            <ul className="subText">
              <li>
                <a href="/">Accueil </a>
              </li>
              <li> / </li>
              <li style={{ fontSize: 20 }}>
                <a href="/technologies">Technologies </a>
              </li>
              <li> / </li>
              <li className="act">
                <a href="/technologies/stationtotaletgps"> Station Totale et GPS </a>
              </li>
            </ul>
          </div>
        </section>
        <section style={{ paddingTop: "40px", backgroundColor: "#F5F3F4" }}>
          <div
            className="apropos"
            style={{ backgroundColor: "#E8E8E4", paddingTop: "30px" }}
          >
            <div
              data-aos="fade-right"
              className="flex_row img_container"
            >
              <img src="./assets/allimages/leica.jpg" />
            </div>
            <div className="flex_row">
              <h1>Station Totale</h1>
              <p className="text">
                Nos stations totales de marque TopCon et Leica sont utilsées
                pour les mesures d'angle et distance. Les stations totales sony
                fréquemment utilisées pour les travaux topografiques, bornage,
                différents levés cadastraux, inspection des bornage, et plus
                encore.
                <br />
                <br />
                Les stations totales permettent d'enregistrer les mesures dans
                une carte mémoire, et de les tranférer à l'ordinateur pour en
                faire sortir des plans en DWG ou DXF en utilisant des logiciels
                de DAO (Dessin Assisté par Ordinateur).
              </p>
            </div>
          </div>
          <div
            className="apropos"
            style={{ backgroundColor: "#E8E8E4", paddingTop: "30px" }}
          >
            <div
              data-aos="fade-right"
              className="flex_row img_container"
            >
              <img src="./assets/allimages/gps.jpg" />
            </div>
            <div className="flex_row">
              <h1>GPS</h1>
              <p className="text">
                Le système mondial de navigation par satellite (GNSS) fait
                référence à une constellation de satellites fournissant des
                signaux depuis l'espace qui transmettent des données de
                positionnement et de synchronisation aux récepteurs GNSS. Les
                récepteurs utilisent ensuite ces données pour déterminer
                l'emplacement.
                <br />
                <br />
                Le GNSS s'est rapidement adapté à la topographie, car il peut
                donner une position (Latitude, Longitude et Hauteur)
                directement, sans qu'il soit nécessaire de mesurer les angles et
                les distances entre les points intermédiaires. Le contrôle des
                levés pouvait maintenant être établi presque n'importe où et il
                suffisait d'avoir une vue dégagée du ciel pour que le signal des
                satellites GPS, GLONASS ou tout autre satellite puisse être reçu
                clairement.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
</>
  );
}

export default StationTotal;
