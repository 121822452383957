export const  profilData = [
    {
        title: 'Prestations Cadastrales',
		price: 'À partir de 300 dh',
        text: '',
		features: [ 'Bornage','Lotissement', 'Morcellement / Fusion', 'Copropriété'],
        adddata: true
    }, {
        title: 'Plans Topographiques',
		price: 'À partir de 3000 dh',
        text: '',
		features: [ 'Plan côté','Plan / État parcellaire', 'Plan de partagr', 'Plan de délimitation'],
        adddata: true

    }, {
        title: 'Modélisation BIM',
		price: 'À partir de 3000 dh',
        text: '',
		features: [ 'Visite virtuelle en 360°','Maquette 3D en format IFC / Revit', 'Applications de Réalité Augmentée et Réalité virtuelle AR/VR'],
        adddata: false

    }, {
        title: 'Photogrammétrie',
		price: 'Prix négociable selon le besoin',
		features: [ 'Stereo / Aero','Restitution', "Traitement d'images"],
        adddata: false

    },
    {
        title: 'Applications SIG / Cartographie',
		price: 'Prix selon le cas',
        text: '',
		features: [ 'Développement des applications SIG','Gestion des données spatiales' ],
        adddata: false

    }, 
    {
        title: "Conduites d'eau potable",
		price: 'Prix selon le cas',
        text: '',
		features: [ 'Parcellaire','Bande côtée', 'piquetage' ],
        adddata: false

    }, 
    {
        title: 'Projets VRD',
		price: 'Prix selon le cas',
        text: '',
		features: [ 'Assainissement et infrastructure','Voie ferrée', 'Implantations'],
        adddata: false
    },
    , 
    {
        title: 'Prestations particulières',
		price: 'Prix selon le cas',
        text: '',
		features: [ 'Expertise immobilière','Suivi de chantier', 'Carrières', 'Rétablissement de bornes', 'Agriculture intelligente', 'Levé bathymétrique'],
        adddata: false
    }

];