import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { auth, db } from "../../firebase";
import {setDoc, doc, Timestamp, addDoc, collection} from 'firebase/firestore';
import axios from 'axios';



const currencies = [
  {
    value: 'TN',
    label: 'Terrain nu (TN)',
  },
  {
    value: 'Terrain Agricole',
    label: 'Terrain Agricole',
  },
  {
    value: 'Construit',
    label: 'Immeuble Construit',
  },

  
];

const useStyles = makeStyles({
  flexGrow: {
    flex: "1",
  },
  button: {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px 25px",
    "&:hover": {
      backgroundColor: "#000",
      color: "#fff",
    },
  },
  btn: {
    backgroundColor: "#00B4D8",
    color: "#fff",
    padding: "10px 25px",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#00B4D8",
    },
  },
});

export default function FormDialog({ name, email, style, offre, adddata }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const [data, setData] = useState({
    name: '',
    email: email,
    phone: "",
    place: "",
    consistance: "",
    area: 0,
    specifications: "",
    comments: "",
    ref: ""
  });

  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = async () => {
    if (
      data.name === "" ||
      data.email === "" ||
      data.phone === "" ||
      data.place === "" ||
      data.area === 0 
    ) {
      setError("Merci de remplir tous les champs!");
      return;
    };

    if (adddata && data.consistance === "") {
      setError("Merci de remplir tous les champs!");
      return;
    } else if (adddata && data.ref === "") {
      setError("Merci de remplir tous les champs!");
      return;
    }
    let details = {
      name: name,
      objet: offre,
      project: data.name,
      email: data.email,
      phone: data.phone,
      place: data.place,
      area: data.area,
      consistance: data.consistance === "" ? "Non spécifiée" : data.consistance,
      ref: data.ref === "" ? "Référence foncière non spécifiée" : data.ref,
      specifications: data.specifications === "" ? "Pas de spécifications" : data.specifications, 
      comments: data.comments === "" ? "Pas de commentaires" : data.comments,

    };
    setError(null);
    setLoading(true);
    try {
      axios
        .post("https://tratopbackend.herokuapp.com/devis", details)
        .then((res) => console.log("Devis message sent"))
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err.message);
    }
    const usersRef = collection(db, 'users', auth.currentUser.uid, 'projets');
    await addDoc(usersRef, {
      uid: auth.currentUser.uid,
      email: email,
      nomclient: name,
      nomprojet: data.name,
      type: offre,
      lieu: data.place,
      superficie: data.area,
      specifications: data.specifications ? data.specifications : null,
      commentaires: data.comments === "" ? null: data.comments,
      ref: data.ref === "" ? null : data.ref,
      specifications: data.specifications === "" ? null : data.specifications, 
      devis: "en attente",
      projet: "Non commencé",
      remarques: "",
      livrables: "",
      createdAt: Timestamp.fromDate(new Date())
    })
    setLoading(false);
    alert("Devis envoyé!");
    setData({
        email: email,
        phone: "",
        place: "",
        area: 0,
        specifications: "",
        comments : ""
    })
    setOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        style={{ ...style }}
        className={classes.button}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Demander un devis
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ flex: 1, textAlign: "center", fontSize: 24 }}>
          Demande de Devis
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontSize: 24, color: "#00B4D8" }}>
            {offre} :
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email (*)"
            type="email"
            fullWidth
            variant="standard"
            value={data.email}
            onChange={(e) => {
              setData({
                ...data,
                email: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nom du projet (*)"
            type="name"
            fullWidth
            variant="standard"
            value={data.name}
            onChange={(e) => {
              setData({
                ...data,
                name: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Téléphone (*)"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
            value={data.phone}
            onChange={(e) => {
              setData({
                ...data,
                phone: e.target.value,
              });
            }}
          />
          <TextField
          
            autoFocus
            margin="dense"
            id="lieu"
            label="Lieu du projet / commune (*)"
            type="text"
            fullWidth
            variant="standard"
            value={data.place}
            onChange={(e) => {
              setData({
                ...data,
                place: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ref"
            label="Référence Foncière (*)"
            fullWidth
            variant="standard"
            value={data.ref}
            onChange={(e) => {
              setData({
                ...data,
                ref: e.target.value,
              });
            }}
          />
          {
            adddata ? <TextField
            style={{marginTop: 20, width: '100%'}}
            id="outlined-select-currency"
            select
            fullWidth
            label="Consistance (*)"
            value={data.consistance}
            onChange={(e) => {
              setData({
                ...data,
                consistance: e.target.value,
              });
            }}
          >
            {currencies.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField> : null
          }
          <TextField
            autoFocus
            margin="dense"
            id="area"
            label="Superficie en hectare (ha) (*)"
            type="number"
            fullWidth
            variant="standard"
            value={data.area}
            onChange={(e) => {
              setData({
                ...data,
                area: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="projet"
            label="Spécifications du projet (Optionnel)"
            type="text"
            fullWidth
            variant="standard"
            value={data.specifications}
            onChange={(e) => {
              setData({
                ...data,
                specifications: e.target.value,
              });
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Commentaires (Optionnel)"
            type="text"
            fullWidth
            variant="standard"
            value={data.comments}
            onChange={(e) => {
              setData({
                ...data,
                comments: e.target.value,
              });
            }}
          />
        </DialogContent>
        <p style={{fontSize: 14, color: 'red'}}>{error}</p>
        <DialogActions style={{ flex: 1, flexDirection: "row" }}>
          {loading ? <CircularProgress color="inherit" style={{paddingLeft: 10}}/> : (
            <Button className={classes.btn} onClick={handleSend}>
              Envoyer
            </Button>
          )}
          <Button className={classes.btn} onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
