export const data = [
	{
		title: 'Al - Oumrane',
		description: 'We have provided services to top clients in tech industry',
		image: './partenaires/al-oumrane.png',
	},
	{
		title: 'Autoroutes du Maroc',
		description:
			'Our clients happily stay with our services for more several years now. See real reviews from our clients.',
		image: './partenaires/ADM-maroc.jpg',
	},
	{
		title: 'L\'Agence nationale de la conservation foncière, du cadastre et de la cartographie',
		description: 'Learn more about our security systems to make sure your data is always safe',
		image: './partenaires/Anfcc.png',
	},
	{
		title: 'Agence nationale des équipements publiques',
		description: 'Our team consists of the best experts in the industry, learn about them',
		image: './partenaires/Anep.png',
	},
	{
		title: 'Agence Régionale d\'Exécution des Projets',
		description: 'Find more about hardware and software used for your service',
		image: './partenaires/AREP.jpg',
	},
	{
		title: "Agence du bassin hydraulique de Souss Massa",
		image : "https://www.soussmassa.ma/sites/default/files/styles/vactory_partner_thumbnail/public/partner_visual/logos%20partenaires-04.png?itok=bXx21Al1"
	}, 
	{
		title: "Office National de l'Électricité et de l'Eau Potable ",
		image : "https://www.dreamjob.ma/wp-content/uploads/2021/01/ONEE-ONEP-Concours-Emploi-Recrutement-2.png"
	}, 
	{
		title: "Office Régional de mise en valeur agricole",
		image : "./partenaires/ORMVASM.jpg"
	},
	{
		title: "Ministère de l'Équipement, du Transport, de Logistique et de l'eau",
		image : "./partenaires/routes.png"
	}, 
	{
		title: "Groupe Bicha",
		image : "https://docplayer.fr/docs-images/45/14543228/images/page_2.jpg"
	}, 
	{
		title: "Agence Urbaine Agadir",
		image : "https://soussplus.com/wp-content/uploads/2020/01/31.png"
	}, 
	
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
