import React, { useState } from "react";
import "./contact.css";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaPaperPlane,
  FaMapMarker,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";
import Footer from "../components/Footer/Footer";
import axios from "axios";

function ContactUs() {
  const [status, setStatus] = useState("Submit");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    let { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    console.log(details);
    try {
      axios
        .post("https://tratopbackend.herokuapp.com/contact ", details)
        .then((res) => {
          console.log("Contact message sent");
          setStatus("Submit");
          e.target.reset();
          alert("Message envoyé!");
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } catch (err) {
      console.log(err.message);
    }
    // let response = await fetch("http://localhost:5000/contact", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(details),
    // });
  };
  return (
    <>
      <section id="contact">
        <h1 class="section-header">Contactez-nous</h1>

        <div className="contact-wrapper">
          <form
            id="contact-form"
            className="form-horizontal"
            role="form"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="NOM"
                  name="name"
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="EMAIL"
                  name="email"
                  required
                />
              </div>
            </div>

            <textarea
              className="form-control"
              rows="5"
              placeholder="MESSAGE"
              name="message"
              required
            />

            {status === "Submit" && (
              <button
                className="btn btn-primary send-button"
                id="submit"
                type="submit"
                value="SEND"
              >
                <div className="alt-send-button">
                  <FaPaperPlane />
                  <span className="send-text">Envoyer</span>
                </div>
              </button>
            )}
          </form>

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item">
                <FaMapMarker />
                <span className="contact-text place">
                  Place Des Taxis, Agadir
                </span>
              </li>

              <li className="list-item">
                <FaPhone />
                <span className="contact-text phone">
                  <a href="tel:+212 5 28 82 74 91" title="Appel">
                    (212) 5 28 82 74 91
                  </a>
                </span>
              </li>

              <li className="list-item">
                <FaEnvelope />
                <span className="contact-text gmail">
                  <a href="mailto:ingtratop@gmail.com" title="Envoyer un email">
                    ingtratop@gmail.com
                  </a>
                </span>
              </li>
            </ul>
            <ul className="social-media-list">
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  href="https://ma.linkedin.com/company/tratop-ingénierie"
                  target="_blank"
                  className="contact-icon"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a href="#" target="_blank" className="contact-icon">
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
