export default function validateForm({ name, company, email, password, confirmPass }) {
	if (!name.trim()) {
		return 'Le nom est requis.';
	}
	if (!company.trim()) {
		return 'Le nom de l\'entreprise est requis.';
	}
	// else if (!/^[A-Za-z]+/.test(name.trim())) {
	//   errors.name = 'Enter a valid name';
	// }

	const regex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!email) {
		return 'Email requis.';
	} else if (regex.test(email.toLocalLowerCase)) {
		return 'Addresse email invalide.';
	}
	if (!password) {
		return 'Mot de passe requis.';
	} else if (password.length < 6) {
		return 'Mot de passe doit être de 6 caractères minimum.';
	}

	if (!confirmPass) {
		return 'Confirmez votre mot de passe.';
	} else if (confirmPass !== password) {
		return 'Les mots de passe ne correspondent pas.';
	}
	return null;
}



