import React from 'react';
import Form from '../components/Form/Form';
import Footer from '../components/Footer/Footer';


function SignUp() {
	return (
		<>
			<Form />
			<Footer />
		</>
	);
}

export default SignUp;
