import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import { useHistory } from "react-router-dom";
import { auth, db } from "../firebase";
import { signOut } from "firebase/auth";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { profilData } from "../data/ProfilData";
import DataStyle from "../components/MaterialUI/DataStyle";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";
import axios from 'axios';

import {
  PricingSection,
  PricingWrapper,
  PricingContainer,
  PricingCardInfo,
  PricingCardPlan,
  PricingCardCost,
  PricingCardFeatures,
  PricingCardText,
  PricingCardFeature,
  PricingCard,
} from "../components/Pricing/PricingStyles";
import { isMobile } from "react-device-detect";
import Dialog from "../components/MaterialUI/Dialog";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const drawerWidth = 280;

const bgColor = "#fff";

function Profil() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataRows, setRows] = useState([]);
  const [projetsSelection, setProjets] = useState([]);
  const [error, setError] = useState(null);
  const [helpLoading, setHelpLoading] = useState(null);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { user } = useContext(AuthContext);
  const [username, setUsername] = useState(null);
  const [drawerData, setDrawerData] = useState({
    projets: false,
    nosoffres: false,
    questions: false,
  });
  const [help, setHelp] = useState({
    title: "",
    phone: "",
    projet: "",
    message: "",
  });
  useEffect(() => {
    setLoading(true);
    getDoc(doc(db, "clients", auth.currentUser.uid)).then((docSnap) => {
      if (docSnap.exists) {
        setUsername(docSnap.data().name);
      }
    });
    const usersRef = collection(db, "users", auth.currentUser.uid, "projets");
    const q = query(usersRef, orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      let projectsData = [];
      let rows = [];
      let projetsSelect = [];
      querySnapshot.forEach((doc) => {
        projectsData.push(doc.data());
        rows.push({
          id: doc.id,
          nom: doc.data().nomprojet,
          type: doc.data().type,
          devis:
            doc.data().devis === "en attente" ? "En attente ⌛" : "Accepté ✔️",
          projet: doc.data().projet,
          delais: doc.data().delais?.toDate(),
          created: doc.data().createdAt.toDate(),
          remarques: doc.data().remarques,
          livrables: doc.data().livrables,
        });
        projetsSelect.push({
          value: doc.data().nomprojet,
          label: doc.data().nomprojet,
        });
      });
      if (projectsData !== []) {
        setIsClient(true);
        setData(projectsData);
        setRows(rows);
        setProjets(projetsSelect);
      }
    });
    console.log(dataRows);
   
    setLoading(false);
 
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const askHelpHandler = async () => {
    if (
      help.title === "" ||
      help.email === "" ||
      help.phone === "" ||
      help.projet === "" || help.message=== ""
    ) {
      setError("Merci de remplir tous les champs.");
      return;
    }
    let details = {
      name: username,
      title: help.title,
      email: help.email,
      phone: help.phone,
      projet: help.projet,
      message: help.message 
    };
    setError(null);
    setHelpLoading(true);
    try {
      axios
        .post("https://tratopbackend.herokuapp.com/help", details)
        .then((res) => console.log("Help message sent"))
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err.message);
    }
    setHelpLoading(false);
    alert("Votre message est envoyé. Notre équipe répondera à votre question dans 24h. Merci pour votre confiance.");
    setHelp({
      title: "",
      phone: "",
      projet: "",
      message: "",
    });
  };

  const history = useHistory();
  const drawer = (
    <div style={{wordWrap: "break-word"}}>
      <Divider />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          
        }}
      >
        <AccountCircleIcon style={{ color: "#4A4E69" }} />
        <p style={{ color: "black" }}>{username ? username : ""}</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MailIcon style={{ color: "#4A4E69" }} />
        <p style={{ color: "black" }}>{user.email}</p>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() =>
            setDrawerData({
              projets: true,
              nosoffres: false,
              questions: false,
            })
          }
        >
          <ListItemIcon>
            <AccountTreeIcon style={{ color: "#660708" }} />
          </ListItemIcon>
          <ListItemText primary="Projets" />
        </ListItem>

        <ListItem
          button
          onClick={() =>
            setDrawerData({
              projets: false,
              nosoffres: true,
              questions: false,
            })
          }
        >
          <ListItemIcon>
            <HeadsetMicIcon style={{ color: "#660708" }} />
          </ListItemIcon>
          <ListItemText primary="Nos offres" />
        </ListItem>
        <ListItem
          button
          onClick={() =>
            setDrawerData({
              projets: false,
              nosoffres: false,
              questions: true,
            })
          }
        >
          <ListItemIcon>
            <HelpIcon style={{ color: "#660708" }} />
          </ListItemIcon>
          <ListItemText primary="Aide" />
        </ListItem>
      </List>
      <Divider />
      <div style={{ bottom: 0 }}>
        <ListItem
          button
          onClick={async () => {
            await signOut(auth);
            history.replace("/signUp");
          }}
        >
          <ListItemIcon>
            <LogoutIcon style={{ color: "#4A4E69" }} />
          </ListItemIcon>
          <ListItemText primary="Se déconnecter" style={{ color: "#4A4E69" }} />
        </ListItem>
      </div>
    </div>
  );

  const Header = () => {
    return (
      <>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { sm: "none" },
              marginTop: 0,
              position: "absolute",
              top: 80,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            marginTop: 0,
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: 0,
                backgroundColor: "#fff",
                paddingTop: 0,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: 10,
                paddingTop: 0,
                backgroundColor: "#fff",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      </>
    );
  };
  const ProjetComponent = ({ title, rows }) => {
    console.log(rows)
    return (
      <Box>
        <CssBaseline />
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div
            style={{
              display: "block",
              marginLeft: isMobile ? 20 : 280,
            }}
          >
            <h2
              style={{
                color: "#000",
                fontWeight: 36,
                fontSize: "bold",
                paddingTop: 20,
                letterSpacing: 6,
                flex: 1,
                textDecoration: "underline",
              }}
            >
              {title}
            </h2>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: width,
            }}
          >
            {" "}
            {rows.length !== 0 ? (
              <div
                style={{
                  marginLeft: isMobile ? 0 : 280,
                  width: isMobile ? "100%" : width - 280,
                }}
              >
                <DataStyle rows={rows} />
              </div>
            ) : (
              <div
                style={{
                  marginLeft: isMobile ? 0 : 280,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: width - 240,
                }}
              >
                <p
                  style={{
                    margin: 10,
                    color: "#000",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Devenez un client de TraTop et suivez vos projets à distance !
                </p>
                <Button
                  onClick={() =>
                    setDrawerData({
                      projets: false,
                      nosoffres: true,
                    })
                  }
                  style={{
                    backgroundColor: "#D6E6FF",
                    color: "#000",
                    padding: "15px",
                  }}
                >
                  Commencer un projet
                </Button>
              </div>
            )}
          </div>
        </div>
      </Box>
    );
  };
  if (drawerData.projets) {
      return <ProjetComponent rows={dataRows} title="Vos Projets" />;
  }

  if (drawerData.nosoffres) {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />

          <PricingContainer>
            {profilData.map((card, index) => (
              <PricingCard key={index}>
                <PricingCardInfo
                  style={{ backgroundColor: bgColor, position: "relative" }}
                >
                  <PricingCardPlan>{card.title}</PricingCardPlan>
                  <PricingCardFeatures>
                    {card.features.map((feature, index) => (
                      <PricingCardFeature key={index}>
                        {feature}
                      </PricingCardFeature>
                    ))}
                  </PricingCardFeatures>
                  <Dialog
                    style={{ position: "absolute", bottom: 20 }}
                    name={username}
                    email={user.email}
                    offre={card.title}
                    adddata={card.adddata}
                  />
                </PricingCardInfo>
              </PricingCard>
            ))}
          </PricingContainer>
        </Box>
      </Box>
    );
  }
  if (drawerData.questions) {
    if (projetsSelection.length === 0) {
      return <ProjetComponent rows={[]} title="Aide" />;
    }
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Header />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 5
            }}
          >
            <Card
              style={{
                maxWidth: 500,
                padding: "20px 5px",
                backgroundColor: "#F5CAC3",
                margin: "0 auto",
                width: "100%",
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Envoyez vos questions / Réclamations
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  gutterBottom
                >
                  Notre équipe vous contactera dans moins de 24h.
                </Typography>
                <Grid xs={12} sm={6} item style={{ margin: "10px 0" }}>
                  <TextField
                    label="Titre du message"
                    variant="outlined"
                    fullWidth
                    required
                    value={help.title}
                    onChange={(e) =>
                      setHelp({
                        ...help,
                        title: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} item style={{ margin: "10px 0" }}>
                  <TextField
                    label="Téléphone"
                    variant="outlined"
                    fullWidth
                    required
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    value={help.phone}
                    onChange={(e) =>
                      setHelp({
                        ...help,
                        phone: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} item style={{ margin: "10px 0" }}>
                  <TextField
                    label="Sélectionner le projet"
                    variant="outlined"
                    fullWidth
                    select
                    required
                    value={help.projet}
                    onChange={(e) =>
                      setHelp({
                        ...help,
                        projet: e.target.value,
                      })
                    }
                  >
                    {projetsSelection.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} style={{ margin: "10px 0" }}>
                  <TextField
                    label="Message"
                    multiline
                    rows={4}
                    placeholder="Écrivez votre message/question ici."
                    variant="outlined"
                    fullWidth
                    required
                    value={help.message}
                    onChange={(e) =>
                      setHelp({
                        ...help,
                        message: e.target.value,
                      })
                    }
                  />
                </Grid>
                <p style={{ fontSize: 14, color: "red" }}>{error}</p>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {helpLoading ? null : (
                  <Button
                    onClick={askHelpHandler}
                    style={{
                      backgroundColor: "#000",
                      color: "#F5CAC3",
                      width: "100%",
                      padding: "10px 0",
                    }}
                  >
                    Envoyer
                  </Button>
                )}
              </CardActions>
            </Card>
          </div>
        </Box>
      </Box>
    );
  }
  if (dataRows !== []) {
    return <ProjetComponent rows={dataRows} title="Vos Projets" />;
  }
}

export default Profil;
