import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {Link } from 'react-router-dom';
import './drone.css';
import Footer from '../components/Footer/Footer';


function Drone() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div>
        <section className="pageBannerD">
          <div className="imageLayer img"></div>
          <div data-aos="fade-up" className="autoContainer">
            <h1>Drone</h1>
            <ul className="subText">
              <li>
                <Link to="/">Accueil </Link>
              </li>
              <li> / </li>
              <li style={{ fontSize: 20 }}>
                <Link to="/technologies">Technologies </Link>
              </li>
              <li> / </li>
              <li className="act">
                <Link to="/technologies/drone"> Drone </Link>
              </li>
            </ul>
          </div>
        </section>
        <section style={{ backgroundColor: "black", paddingTop: "40px" }}>
          <div className="aproposD">
            <div
              data-aos="fade-right"
              className="flex_rowD img_container"
            >
              <img src="./assets/allimages/drone.jpg" />
            </div>
            <div className="flex_rowD" style={{ color: "#fff" }}>
              <h1>La nouvelle technologie Drone</h1>
              <p className="text">
                Les drones sont des aéronefs pilotés automatiquement par le
                biais d'une télécommande. Les drones peuvent être configurés
                selon l'usage et la destination des livrables. Ils sont utilisés
                dans plusieurs domaines : aménagement du territoire, prévention
                et gestion des risques naturels, inspection des ponts et
                barrages, télédétection et imagerie pour l'agriculture de
                précision, etc...
              </p>
            </div>
          </div>
          <div className="aproposD">
            <div
              data-aos="fade-right"
              className="flex_rowD img_container"
            >
              <img src="./assets/allimages/drone2.jpg" />
            </div>
            <div className="flex_rowD" style={{ color: "#fff" }}>
              <h1>Notre drone - Falcon 8+ </h1>
              <p className="text">
                TraTop Ingénierie dispose du drone de type Falcon 8+, qui offre
                des données détaillées avec une précision milimétrique GSD. La
                caméra du drone 36MP offre une gamme d'images avec de très haute
                résolution. <br /> <br /> Le système Intel Falcon 8+ de qualité
                professionnelle combine une conception industrielle de haute
                qualité avec un contrôle de mission complet. Il comporte
                plusieurs options de fonctionnalités permettant de se conformer
                aux exigences les plus élevées de l'industrie et générer de
                données automatisées d'une façon rapide.
              </p>
            </div>
          </div>
          <div className="aproposD">
            <div
              data-aos="fade-right"
              className="flex_rowD img_container"
            >
              <img src="./assets/allimages/photogrammetrie.png" />
            </div>
            <div className="flex_rowD" style={{ color: "#fff" }}>
              <h1>Les livrables</h1>
              <p className="text" >
                <ul>
                  <li>Un nuage de points hébérgé sur un site web. </li>
                  <li> Une orthophoto avec haute résolution </li>
                  <li> Un Modèle numérique de Terrain </li>
                  <li>Un modèle numérique de Surface</li>
                  <li>Des cartes NDVI</li>
                </ul>
              </p>
            </div>
          </div>
        </section>
      </div>
    <Footer />
    </>
  );
}

export default Drone;
