import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
  {
    name: "Facebook",
    icon: iconStyle(FaFacebook),
  },
  {
    name: "YouTube",
    icon: iconStyle(FaYoutube),
  },
  {
    name: "Twitter",
    icon: iconStyle(FaTwitter),
  },
  {
    name: "LinkedIn",
    icon: iconStyle(FaLinkedin),
  },
];

export const footerData = [
  {
    title: "Technologies",
    footerlinks: [
      {
        link: "Station Total et GPS",
        path: "/stationtotaletgps",
      },
      { link: "Scanner Laser", path: "/scannerlaser" },
      { link: "Mobile Mapping System", path: "/mms" },
      { link: "SLAM", path: "/scannerlaser" },
      { link: "Drone", path: "/drone" },
    ],
    links: [
      "Station Total et GPS",
      "Scanner Laser",
      "Mobile Mapping System",
      "SLAM",
      "Drone",
    ],
  },
  {
    title: "About Us",
    links: ["Mission", "Certification", "Recrutement", "Support"],
	footerlinks: [
		{
		  link: "Mission",
		  path: "/about",
		},
		{ link: "Recrutement", path: "/contact" },
		{ link: "Support", path: "/contact" },
	  ],
  },
];
