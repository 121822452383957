export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Créée in 2001',
	},
	headline: "Une expérience de plus de 20 ans",
	description: 'Nous avons acquis plusieurs compétences techniques, matérielles et humaines, ainsi que la confiance de plusieurs clients.',
	buttonLabel: 'Lire davantage',
	linkTo: '/about',
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: true,
	topLine: {
		text: 'Nous présentons de nouvelles solutions',
	},
	headline: 'Les meilleures pratiques',
	description: 'Nos clients ont bénéficié de solutions digitales et intuitives répondant à leurs besoins.',
	buttonLabel: 'Voir un projet',
	linkTo: '/contact',
	imgStart: 'start',
	img: './assets/svg/Connection.svg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: 'Pourquoi TraTop? ',
	description:
		'Notre équipe composée de personnes expérimentées, motivées et créatives s\'assure de vous livrer les meilleures solutions, ainsi que de vous accompagner à mieux comprendre et travailler avec vos données en 3D.',
	buttonLabel: 'Demander une démo',

	linkTo: '/contact',
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};
