import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { Button, Container, MainHeading } from "../../globalStyles";
import {
  HeroVideo,
  HeroSection,
  HeroText,
  ButtonWrapper,
  HeroButton,
} from "./HeroStyles";
import BackgroundSlider from "react-background-slider";
import { AuthContext } from "../../context/auth";


const Hero = () => {
  const { user } = useContext(AuthContext);

  return (
    <HeroSection>
      <BackgroundSlider
        images={
          [ "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80","https://images.unsplash.com/photo-1435575653489-b0873ec954e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
          "https://images.unsplash.com/photo-1462899006636-339e08d1844e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"]
        }
        duration={10}
        transition={2}
      />
      <Container>
        <MainHeading>Nos rendons vos données plus intelligentes</MainHeading>
        <HeroText>
          Une expérience de plus de 20 ans, dans le domaine foncier,
          construction, routier, expertise, et plus encore.
        </HeroText>
        <ButtonWrapper>
          <Link to="/about">
            <Button>Lire davantage</Button>
          </Link>
          <Link to={user ? "/ClientSpace" : "/signUp"}>
          <HeroButton>Devenir Client</HeroButton>
          </Link>
        </ButtonWrapper>
      </Container>
    </HeroSection>
  );
};

export default Hero;
