import React from 'react';

import { BsFillShieldLockFill } from 'react-icons/bs';
import { IoIosOptions } from 'react-icons/io';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { BiSupport, BiDollar } from 'react-icons/bi';
import { GrHostMaintenance } from 'react-icons/gr';
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const featuresData = [
	{
		name: 'Sécurité de données',
		description: 'Nous assurons la sécurité de vos données.',
		icon: iconStyle(BsFillShieldLockFill),
		imgClass: 'one',
	},
	{
		name: 'Données intelligentes et intuitives',
		description: 'Nos hébérgeons vos données de façon à rendre l\'accès à l\'information plus intuitif et facile.',
		icon: iconStyle(IoIosOptions),
		imgClass: 'two',
	},
	{
		name: '24/7 Support',
		description: 'Notre équipe est disponible 24/7 pour vous accompagner en cas de besoin.',
		icon: iconStyle(BiSupport),
		imgClass: 'three',
	},
	{
		name: 'Prix',
		description: 'Nous offrons la meilleure qualité avec le meilleur prix.',
		icon: iconStyle(BiDollar),
		imgClass: 'four',
	},

];
