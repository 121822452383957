export const pricingData = [
	{
		title: 'Topographie',
		price: 'Station Totale et GPS',
		url: '/stationtotaletgps',
		features: ['Parmis les premiers à acquérir le GPS', 'Haute précision de relevés topographiques et géodésiques'],
	},
	{
		title: 'Photogrammétrie',
		price: 'Drone',
		url: '/drone',
		features: [
			'Haute précision',
			'Orthophotographies de la zone à capturer',
			'Nuage de points',
			
		],
	},
	{
		title: 'Lasergrammétrie',
		price: 'Scanner Laser',
		url: '/scannerlaser',
		features: [
			'Scanner Laser Statique et SLAM',
			'Mesures sur le nuage de points',
			'Visites virtuelles à partir des panoramas. ',
		],
	},

	{
		title: 'Lasergrammétrie',
		price: 'Mobile Mapping System',
		url: '/mms',
		features: ['Technologie de capture de réalité sur véhicule', 'Haute précision et rapidité de livrables'],
	},

];
