// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD7rS4xkt_sMnVGUVB0UpDXi07-OWIdBFo",
    authDomain: "tratopweb.firebaseapp.com",
    projectId: "tratopweb",
    storageBucket: "tratopweb.appspot.com",
    messagingSenderId: "815195491051",
    appId: "1:815195491051:web:4f8846836fa0e17d07ccae",
    measurementId: "G-42R2SP1JQG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {auth, db};
