import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import event from "./one.png";
import { auth, db } from "../firebase";
import { setDoc, doc, Timestamp, addDoc, collection } from "firebase/firestore";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://tratop.ma/">
        Tratop Ingénierie
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Event() {
  const [userRequest, setUserRequest] = React.useState({
    email: "",
    lastname: "",
    name: "",
    profession: "",
    phone: "",
    city: "",
  });
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFailure(false);
    setSuccess(false);
    if (
      !userRequest.email ||
      !userRequest.lastname ||
      !userRequest.name ||
      !userRequest.profession ||
      !userRequest.phone ||
      !userRequest.city
    ) {
      setFailure(true);
      return;
    }
    const eventRef = collection(db, "Event");
    await addDoc(eventRef, {
      email: userRequest.email,
      nom: userRequest.lastname,
      prenom: userRequest.name,
      profession: userRequest.profession,
      phone: userRequest.phone,
      city: userRequest.city,
      createdAt: Timestamp.fromDate(new Date()),
    });
    // alert(
    //   "Votre inscription a été prise en considération. Merci pour votre intérêt. Vous receverez prochainement un email confirmant votre inscription."
    // );
    setUserRequest({
      email: "",
      lastname: "",
      name: "",
      profession: "",
      phone: "",
      city: "",
    });
    setSuccess(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${event})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
          style={{ marginTop: 80 }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 20,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Inscription
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="lastname"
                label="Nom"
                name="lastname"
                autoFocus
                value={userRequest.lastname}
                onChange={(e) => {
                  setUserRequest({ ...userRequest, lastname: e.target.value });
                  setFailure(false);
                  setSuccess(false);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Prénom"
                name="name"
                autoFocus
                value={userRequest.name}
                onChange={(e) =>
                 { setUserRequest({ ...userRequest, name: e.target.value })
                 setFailure(false);
                 setSuccess(false);}
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="profession"
                label="Profession"
                name="profession"
                autoFocus
                value={userRequest.profession}
                onChange={(e) =>
                  {setUserRequest({ ...userRequest, profession: e.target.value });
                  setFailure(false);
                  setSuccess(false);}
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="city"
                label="Ville"
                name="city"
                autoFocus
                value={userRequest.city}
                onChange={(e) =>
                  {setUserRequest({ ...userRequest, city: e.target.value })
                  setFailure(false);
                  setSuccess(false);
                }
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoFocus
                value={userRequest.email}
                onChange={(e) =>
                  {setUserRequest({ ...userRequest, email: e.target.value })
                  setFailure(false);
                  setSuccess(false);
                }
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Téléphone"
                name="phone"
                autoFocus
                value={userRequest.phone}
                onChange={(e) =>
                 { setUserRequest({ ...userRequest, phone: e.target.value })
                 setFailure(false);
                 setSuccess(false);
                }
                }
              />
              {failure && (
                <Typography component="p" color="red">
                  Merci de remplir tous les champs
                </Typography>
              )}
              {success && (
                <Typography component="p" color="green">
                  Succès ! Votre inscription a bien été prise en compte. 
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#be1522" }}
              >
                Réserver ma place
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
