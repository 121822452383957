
import {Link} from "react-router-dom";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import './mms.css';
import Footer from '../components/Footer/Footer';


function MMS() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div>
        <section className='pageBannerM'>
          <div className='imageLayer img'></div>
          <div data-aos="fade-up" className='autoContainer'>
            <h1>Mobile Mapping System</h1>
            <ul className='subText'>
              <li>
                <Link to="/">Accueil </Link>
              </li>
              <li> / </li>
              <li style={{ fontSize: 20 }}>
                <Link to="/technologies">Technologies </Link>
              </li>
              <li> / </li>
              <li className='act'>
                <Link to="/technologies/stationTotale"> MMS </Link>
              </li>
            </ul>
          </div>
        </section>
        <section style={{ backgroundColor: "#CFE1B9", paddingTop: "40px" }}>
          <div className='apropos'>
            <div
              data-aos="fade-right"
              className='flex_row img_container'
            >
              <img src="./assets/allimages/mms.jpg" />
            </div>
            <div className='flex_row' style={{ color: "#000" }}>
              <h1>Mobile Mapping System</h1>
              <p className='text'>
                Un système de cartographie mobile (MMS) 3 D est un ensemble
                d’éléments ayant pour objet de cartographier en trois dimensions
                une zone. Il est embarqué sur un véhicule et comporte une unité
                de positionnement et de visualisation et traitement.
              </p>
            </div>
          </div>
          <div className='apropos'>
            <div
              data-aos="fade-right"
              className='flex_row img_container'
            >
              <img src='./assets/images/mms.jpg' style={{ height: "450px" }} />
            </div>
            <div className='flex_row' style={{ color: "#000" }}>
              <h1>Mobile Mapping System IP-S3 </h1>
              <p className='text'>
                TraTop Ingénierie dispose du système de cartographie mobile
                IP-S3. C'est un scanner-laser qui collecte 700 000
                points/seconde sur une portée de 100m et il permet l'acquisition
                des données en très peu de temps.
              </p>
            </div>
          </div>
          <div className='apropos'>
            <div
              data-aos="fade-right"
              className='flex_row img_container'
            >
              <img src="./assets/images/mms-result.jpg" />
            </div>
            <div className='flex_row' style={{ color: "#000" }}>
              <h1>Les livrables</h1>
              <p className='text'>
                <ul>
                  <li>Un nuage de points en format ".las". </li>
                  <li>
                    {" "}
                    Un nuage de points hébérgé sur un site web, où vous pouvez
                    lire les coordonnées en X,Y,Z , mesurer les distance, et
                    même exporter en format dxf.{" "}
                  </li>
                  <li>Des fichiers en format SIG (.shp)</li>
                  <li> Des fichiers en format CAD (.dwg) </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default MMS;
