import React from 'react';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterSocialIcon,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, Section } from '../../globalStyles';

function Footer() {
	return (
		<Section padding="2rem 0 1rem 0" >
			<FooterWrapper >
				<FooterGrid justify="space-between">
					<FooterColumn id="footerLogo">
						<FooterLogo to="/">
							<SocialIcon src="./assets/logo.png" />
							TraTop Ingénierie
						</FooterLogo>
						<FooterAddress>
						Place Des Taxis Imm. O.E.S N° 6 Q.I., Souss-Massa-Drâa, Agadir 80000
						</FooterAddress>
						<FooterAddress>
						Email : ingtratop@gmail.com
						</FooterAddress>

						<Row align="center" margin="auto  0 0 0" gap="1rem">
							{footerSocialData.map((social, index) => (
								<FooterSocialIcon
									key={index}
									href="/"
									target="_blank"
									aria-label={social.name}
								>
									{social.icon}
								</FooterSocialIcon>
							))}
						</Row>
					</FooterColumn>
					{footerData.map((footerItem, index) => (
						<FooterLinkItems key={index}>
							<FooterLinkTitle>{footerItem.title}</FooterLinkTitle>
							{footerItem.footerlinks.map((item, linkIndex) => (
								<FooterLink key={linkIndex} to={item.path}>
									{item.link}
								</FooterLink>
							))}
						</FooterLinkItems>
					))}
				</FooterGrid>
				<FooterRights>TraTop Ingénierie © 2022</FooterRights>
			</FooterWrapper>
		</Section>
	);
}

export default Footer;
