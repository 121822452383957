import React , { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {Link} from 'react-router-dom';
import './scanner.css';
import Footer from '../components/Footer/Footer';


function Scanner3d() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div>
        <section className="pageBannerS">
          <div className="imageLayer img"></div>
          <div data-aos="fade-up" className="autoContainer">
            <h1>Scanner 3D</h1>
            <ul className='subText'>
              <li>
                <Link to="/">Accueil </Link>
              </li>
              <li> / </li>
              <li style={{ fontSize: 20 }}>
                <Link to="/technologies">Technologies </Link>
              </li>
              <li> / </li>
              <li className='act'>
                <Link to="/technologies/stationTotale"> Scanner 3D </Link>
              </li>
            </ul>
          </div>
        </section>
        <section style={{ backgroundColor: "#FFE299", paddingTop: "40px" }}>
          <div className='apropos'>
            <div
              data-aos="fade-right"
              className='flex_row img_container'
            >
              <img src="./assets/allimages/lidar-terrestre.jpg" />
            </div>
            <div className='flex_row' style={{ color: "#000" }}>
              <h1>Lasergrammétrie</h1>
              <p className='text'>
                Le « LiDAR», de l’anglais « Light Detection And Ranging » est un
                capteur actif qui, par la mesure du temps de propagation
                aller-et-retour d'un rayonnement lumineux émis par un laser,
                permet de déterminer la position et la distance d'une cible par
                rapport à l'émetteur. Le système LiDAR est une nouvelle
                technique d’acquisition d’informations 3D, faisant ainsi
                apparaître la lasergrammétrie. Grâce à l'avancement des
                technologies, nous sommes aujourd'hui capables de faire la
                capture de la réalité en utilisant la lasergrammétrie.
              </p>
            </div>
          </div>
          <div className='apropos'>
            <div
              data-aos="fade-right"
              className="flex_row img_container"
            >
              <img src="./assets/allimages/gls.jpg" />
              <img src="./assets/allimages/navvis.jpg" />
            </div>
            <div className="flex_row" style={{ color: "#000" }}>
              <h1>Nos scanners </h1>
              <p className='text'>
                TraTop Ingénierie dispose d'un Scanner Statique doté d'une grande polyvalence et flexibilité et
                capturant des nuages de points à haute densité et haute
                précision, ainsi que du scanner mobile NaVvis VLX reconnu pour sa flexibilité et la qualité de ses données à l'intérieur des bâtiments.
              </p>
            </div>
          </div>
          <div className="apropos">
            <div
              data-aos="fade-right"
              className='flex_row img_container'
            >
              <img src="./assets/allimages/cloudpoint.png" />
              <img src="./assets/allimages/bim.ppm" />
            </div>
            <div className='flex_row' style={{ color: "#000" }}>
              <h1>Les livrables</h1>
              <p className='text'>
                <ul>
                  <li>Un nuage de points en format ".las". </li>
                  <li>
                    {" "}
                    Un nuage de points hébérgé sur un site web, où vous pouvez
                    lire les coordonnées en X,Y,Z , mesurer les distance, et
                    même exporter en format dxf.{" "}
                  </li>
                  <li>Plan côté, Plan de situation, Plan de masse...</li>
                  <li> Un modèle BIM établi sur Revit en format IFC. </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Scanner3d;
