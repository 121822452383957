import React, { useState, useContext } from 'react';
import { FaRProject, FaTimes } from 'react-icons/fa';
import { CgMenuRight } from 'react-icons/cg';
import { IconContext } from 'react-icons';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavIcon,
	MobileIcon,
	NavMenu,
	NavLinks,
	NavItem,
} from './NavbarStyles.js';
import { useLocation, useHistory } from 'react-router-dom';
import {AuthContext} from '../../context/auth';
const Navbar = () => {
	const [show, setShow] = useState(false);
	const {user} = useContext(AuthContext);

	const data = [
		{
			to: '/',
			text: 'Accueil',
			id: 'acceuil',
		},
		{
			to: '/about',
			text: 'A propos',
		},
		{
			to: '/technologies',
			text: 'Nos technologies',
		},
		{
			to: '/nosprestations',
			text: 'Nos prestations',
		},
		{
			to: '/contact',
			text: 'Contactez-nous',
		},
		{
			to: user ? '/ClientSpace' : '/SignUp',
			text: user ? 'Mon espace Client': 'Espace Client',
		},
		
	];

	let history = useHistory();
	let location = useLocation();

	const handleClick = () => {
		setShow(!show);
	};

	const scrollTo = (id) => {
		const element = document.getElementById(id);

		element.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const closeMobileMenu = (to, id) => {
		if (id && location.pathname === '/') {
			scrollTo(id);
		}
		history.push(to);
		setShow(false);
	};

	return (
		<IconContext.Provider value={{ color: '#fff' }}>
			<Nav>
				<NavbarContainer>
					<NavLogo to="/">
						<NavIcon src="./assets/logo.png" alt="logo" />
					</NavLogo>
					<MobileIcon onClick={handleClick}>
						{show ? <FaTimes color="#000" /> : <CgMenuRight color="#000" />}
					</MobileIcon>
					<NavMenu show={show}>
						{data.map((el, index) => (
							<NavItem key={index}>
								<NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
									{el.text}
								</NavLinks>
							</NavItem>
						))}
					</NavMenu>
				</NavbarContainer>
			</Nav>
		</IconContext.Provider>
	);
};

export default Navbar;
