
import {Link} from "react-router-dom";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import './nosprestations.css';
import Footer from '../components/Footer/Footer';


function Nosprestations() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div>
        <section className="pageBannerN">
          <div className='imageLayer img'></div>
          <div data-aos="fade-up" className='autoContainer'>
            <h1>Our Work</h1>
            <ul className='subText'>
              <li>
                <Link to="/">Accueil </Link>
              </li>
              <li> / </li>
              <li className='act'>
                <Link to="/nosprestations"> Nos prestations</Link>
              </li>
            </ul>
          </div>
        </section>
        <section>
          <div
            className='container'
            style={{ backgroundColor: "#C5BEC4" }}
          >
            <img src="./assets/images/Cadastre.jpg" />
            <div className='classText'>
              <h1>Travaux cadastraux</h1>
              <p style={{ color: "black" }} className='paragraph'>
                TraTop Ingénierie s'occupe de tous les travaux cadastraux,
                notamment :
              </p>
              <ul>
                <li>Les plans de bornage</li>
                <li>Les plans de mise à jour</li>
                <li>Les travaux de lotissement</li>
                <li>La copropriété</li>
                <li>Les morcellements </li>
                <li>Rétablissement des bornes des propriétés</li>
              </ul>
              <div className='btn'>
                <a className='button' href="/">
                  <p>Demander un devis</p>
                </a>
              </div>
            </div>
          </div>
          <div
            className='container'
            style={{ backgroundColor: "#4A4E69" }}
          >
            <div className='classText' style={{ color: "#fff" }}>
              <h1> Infrastructure </h1>
              <p className='paragraph'>
                TraTop Ingénierie s'occupe de toutes les prestations
                d'infrastructure à savoir :
              </p>
              <ul>
                <li style={{ color: "#fff" }}>Projets Routiers</li>
                <li style={{ color: "#fff" }}>
                  Ouvrages d'arts : Inspection, Auscultation, contrôle et suivi.{" "}
                </li>
                <li style={{ color: "#fff" }}>Les ports</li>
              </ul>
              <div className='btn'>
                <a
                  className='button'
                  style={{ backgroundColor: "#fff", color: "black" }}
                  href="/"
                >
                  <p style={{color: '#000'}}>Demander un devis</p>
                </a>
              </div>
            </div>
            <img src="./assets/images/infra.png" />
          </div>
          <div
            className='container'
            style={{ backgroundColor: "#FFF1E6" }}
          >
            <img src="./assets/images/bim.jpg" />
            <div className='classText'>
              <h1>Modélisation BIM</h1>
              <p style={{color: '#000'}} className='paragraph'>
                Les nuages de points acquis par les scanners sont exploitables
                sur des logiciels comme Revit ou InfraWorks pour :
              </p>
              <ul>
                <li>
                  Création de maquettes 3D apte d'être synchronisée avec
                  d'autres personnes.{" "}
                </li>
                <li>
                  La modélisation BIM inclut les bâtiments ainsi que les projets
                  topographiques et d'infrastructures.
                </li>
                <li>
                  Calcul et estimation de coût sous forme de rapports à partir
                  des modèles BIM.
                </li>
                <li>
                  Le travail est livré sous format standard IFC ou format Revit.
                </li>
              </ul>
              <div className='btn'>
                <a className='button' href="/">
                  <p>Demander un devis</p>
                </a>
              </div>
            </div>
          </div>
          <div
            className='container'
            style={{ backgroundColor: "#496F64" }}
          >
            <div className='classText' style={{ color: "#fff" }}>
              <h1> Agriculture de Précision </h1>
              <p className='paragraph'>
                TraTop Ingénierie met à votre disposition toute son expertise en
                matière de photogrammétrie, télédétection et Informatique et
                Communication afin de rendre votre ferme plus intelligente. En
                effet, grâce à notre solution SmartAgri, vous serez capable de :
              </p>
              <ul>
                <li style={{ color: "#fff" }}>
                  Détecter le stress des plantes grâce à une inspection
                  multispectrale en utilisant le drone.{" "}
                </li>
                <li style={{ color: "#fff" }}>
                  Détecter les maladies des plantes dans vos serres en utilisant
                  l'intelligence artificielle.
                </li>
                <li style={{ color: "#fff" }}>
                  Détecter le manque d'irrigation à travers les capteurss
                  installés et recevoir des notifications instantannées des
                  lieux en manque d'eau.
                </li>
                <li style={{ color: "#fff" }}>
                  Accéder à tous vos données à travers l'application SmartAgri
                  où que vous soyez.
                </li>
              </ul>
              <div className='btn'>
                <a
                  className='button'
                  style={{ backgroundColor: "#fff", color: "black" }}
                  href="/"
                >
                  <p style={{ color: "black" }}>Demander un devis</p>
                </a>
              </div>
            </div>
            <img src="./assets/images/agriculture.jpg" />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Nosprestations;
