import React from "react";

function NotFoundPage() {
  return (
    <div style={{marginTop: 120, textAlign: 'center'}}>
      <h1>404 Not Found</h1>
    </div>
  );
}

export default NotFoundPage;
