import * as React from "react";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PaginationItem from "@mui/material/PaginationItem";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#1890ff",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  color:
    theme.palette.mode === "light"
      ? "rgba(0,0,0,.85)"
      : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
  "& .MuiDataGrid-cell": {
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.65)",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
  ...customCheckbox(theme),
}));

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function DataStyle({rows}) {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 10,
    maxColumns: 10,
  });

  const columns = [
    {
      field: "nom",
      headerName: "Nom du projet",
      headerAlign: "center",
      width: 240,
    },
    {
        field: "type",
        headerName: "Type du projet",
        headerAlign: "center",
        width: 240,
      },
    {
      field: "devis",
      headerName: "Etat du devis",
      headerAlign: "center",
      width: 150,
    },
    {
        field: "created",
        headerName: "Date de Création",
        width: 150,
        headerAlign: "center",

        type: "date"
        
      },
      
    {
      field: "projet",
      headerName: "Etat du projet",
      headerAlign: "center",
      width: 150,
    },
    
    {
        field: "delais",
        headerName: "Délais",
        width: 180,
        headerAlign: "center",

        type: "date",
        
      
      },
      {
        field: "remarques",
        headerName: "Remarques",
        headerAlign: "center",
        width: 350,
      },
      {
        field: "livrables",
        headerName: "Livrables",
        headerAlign: "center",
        width: 350,
      },
  ];
  const rowData = [
    {
      id: 1,
      nom: "Dossier mis à jour",
      type: "Dossier Technique",
      devis: "Accepté ✔️",
      projet: "Phase Terrain",
      delais:  new Date(),
      created:  new Date(),

    },
    {
      id: 2,
      nom: "Dossier Copropriété",
      devis: "Accepté ✔️",
      projet: "Phase Bureau",
      delais:  new Date(2022, 6, 22),
      created:  new Date(2022, 6, 22),
    },
    {
      id: 3,
      nom: "Dossier morcellement",
      devis: "En attente ⌛",
      projet: "Non commencé",
      delais:  new Date(2022, 9, 22),
      created:  new Date(2022, 6, 22),
    },
    {
        id: 4,
        nom: "Dossier morcellement",
        devis: "Accepté ✔️",
        projet: "Terminé",
        delais:  new Date(2022, 9, 22),
        created:  new Date(2022, 6, 22),
      },
  ];
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  return (
    <div style={{ height: 400, width: "100%" }}>
      <Box
        sx={{
          height: 400,
          width: 1,
          "& .super-app-theme--Open": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.info.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--Filled": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.success.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--PartiallyFilled": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.warning.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--Rejected": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
            },
          },
          "& .app-theme--Accepté": {
            bgcolor: "#83BD75",
            "&:hover": {
              bgcolor: "#fff"
            },
          },
          "& .app-theme--enattente": {
            bgcolor: '#FFD770',
            "&:hover": {
              bgcolor: "#fff"
            },
          },
          "& .app-theme--Rejeté": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
            },
          },
        }}
      >
        <StyledDataGrid
          checkboxSelection
          pageSize={5}
          rowsPerPageOptions={[5]}
          components={{
            Pagination: CustomPagination,
          }}
          getCellClassName={(params) => {
            if (params.field !== "projet" && params.field !== "devis") {
              return "";
            } 
            if (params.field === "projet" && params.row.projet === "Non commencé") {
                return `super-app-theme--Rejected`;
            } 
            if (params.field === "projet" && params.row.projet === "Terminé") {
                return `super-app-theme--Filled`;
            }
            if (params.field === "projet" && params.row.projet !== "Terminé" && params.row.projet !== "Non commencé") {
                return `app-theme--enattente`;
            }
            if (params.field === "devis" && params.row.devis === "En attente ⌛") {
                return `app-theme--enattente`
            }
            if (params.field === "devis" && params.row.devis === "Accepté ✔️") {
                return `app-theme--Accepté`
            }
          }}
          columns={columns}
          rows={rows}
        />
      </Box>
    </div>
  );
}
