import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import AuthProvider from './context/auth';

//Pages
import ScrollToTop from './WithRouter';
import Home from './pages/Home';
import SignUp from './pages/SignupPage';
import Pricing from './pages/PricingPage';
import AboutPage from './pages/AboutPage';
import Drone from './pages/Drone';
import StationTotal from './pages/StationTotal';
import MMS from './pages/MMS';
import Scanner from './pages/Scanner'
import Nosprestations from './pages/Nosprestations';
import ContactUs from './pages/ContactUs';
import Profil from './pages/Profil';
import PrivateRoute from './pages/PrivateRoute'
import Event from './pages/Event'
import NotFoundPage from './pages/NotFoundPage';


function App() {
	return (
		<AuthProvider>
		<Router>
		<ScrollToTop>
			<GlobalStyle />
			<Navbar />
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/about" exact component={AboutPage} />
				<Route path="/technologies" exact component={Pricing} />
				<Route path="/stationtotaletgps" exact component={StationTotal} />
				<Route path="/drone" exact component={Drone} />
				<Route path="/mms" exact component={MMS} />
				<Route path="/scannerlaser" exact component={Scanner} />
				<Route path="/nosprestations" exact component={Nosprestations} />
				<Route path="/contact" exact component={ContactUs} />
				<Route path="/SignUp" exact component={SignUp} />
				<PrivateRoute path="/ClientSpace" exact component={Profil} />
				<Route path="*" component={NotFoundPage} />
			</Switch>
			</ScrollToTop>
		</Router>
		</AuthProvider>
	);
}

export default App;
