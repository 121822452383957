import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword, signOut
} from "firebase/auth";
import {setDoc, doc, Timestamp} from 'firebase/firestore';
import {useHistory} from 'react-router-dom';
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormButton,
  FormTitle,
  Anchor,
} from "./FormStyles";
import { Container } from "../../globalStyles";
import validateForm from "./validateForm";
import { auth, db } from "../../firebase";

const Form = () => {
	useEffect(()=> {
		console.log(auth.currentUser);
	}, [])
  const [signup, setSignUp] = useState(true);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSignup = async (e) => {
    e.preventDefault();
    const resultError = validateForm({
      name,
      company,
      email,
      password,
      confirmPass,
    });
    if (resultError !== null) {
      setError(resultError);
      return;
    }
    try {
      setLoading(true);
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
	await setDoc(doc(db, 'clients', result.user.uid), {
		uid: result.user.uid,
		name,
		company,
		email,
		createdAt: Timestamp.fromDate(new Date())
	})
      setLoading(false);
	  setName("");
	  setEmail("");
	  setCompany("");
	  setPassword("");
	  setConfirmPass("");
	  setError(null);
	  history.replace('/ClientSpace');
    } catch (err) {
      console.log(err.message);
    }
  
  };

  const handleConnect = async (e) => {
    e.preventDefault();
    
    if (!email || !password) {
      setError("Merci de remplir les champs requis.");
      return;
    }
    try {
      setLoading(true);
	  setError(null);
      const result = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setLoading(false);
	  setEmail("");
	  setPassword("");
	  setError(null);
	  history.replace('/ClientSpace');
    } catch (err) {
		setLoading(false);
		setError(null);
      if (err.code == "auth/invalid-email") {
		  setError("Email invalide.")
	  } else if (err.code == "auth/user-not-found") {
		  setError("Email n'existe pas. Inscrivez-vous si vous êtes nouveau.")
	  } else if (err.code == "auth/wrong-password") {
		  setError("Mot de passe incorrect.")
	  }
    }
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn small>
            <FormTitle>{!signup ? "Se connecter" : "Devenir Client"}</FormTitle>
            <FormWrapper onSubmit={signup ? handleSignup : handleConnect}>
              <FormInputRow>
                {signup ? (
                  <>
                    {" "}
                    <FormLabel>Nom</FormLabel>
                    <FormInput
                      type="name"
                      placeholder="Entrez votre nom"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />{" "}
                  </>
                ) : null}
                {signup ? (
                  <>
                    {" "}
                    <FormLabel>Entreprise</FormLabel>
                    <FormInput
                      type="name"
                      placeholder="Entrez le nom de votre entreprise"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />{" "}
                  </>
                ) : null}
                <FormLabel>Email</FormLabel>
                <FormInput
                  type="email"
                  placeholder="Entrez votre email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FormLabel>Mot de passe</FormLabel>
                <FormInput
                  type="password"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {signup ? (
                  <>
                    {" "}
                    <FormLabel>Confirmer le mot de passe</FormLabel>
                    <FormInput
                      type="password"
                      placeholder="Confirmez votre mot de passe"
                      value={confirmPass}
                      onChange={(e) => setConfirmPass(e.target.value)}
                    />{" "}
                  </>
                ) : null}
              </FormInputRow>

              {loading ? null : (
                <FormButton type="submit">
                  {signup ? "S'inscrire" : "Se connecter"}
                </FormButton>
              )}
             { loading ? null : <Anchor
                onClick={() => {
                  setSignUp(!signup);
                }}
              >
                {signup
                  ? "Déjà inscrit ? Connectez-vous."
                  : "Pas encore inscrit? Inscrivez-vous."}
              </Anchor>}
            </FormWrapper>

            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Form;
